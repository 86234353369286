let serverAddress: string;
if (process.env.NODE_ENV == 'production') {
    serverAddress = "https://server.carbonicx.top";
} else {
    serverAddress = "http://localhost:9000";
}

function server(route: string): string {
    return serverAddress + route;
}

export default server;
